<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <small>
      Users' testresults will appear here.
    </small>
    <hr />
    <el-form label-position="right" label-width="80px" @submit.native.prevent>
      <el-form-item label="Test">
        <el-radio
          v-for="(test, index) in Tests"
          :key="index"
          v-model="searchTest.testType"
          @input="search"
          :label="test"
          border
        >
          <b class="test-color">{{ test }}</b></el-radio
        >
      </el-form-item>
      <!-- <el-form-item label="Type" :model="searchTest">
        <el-radio
          v-model="searchTest.type"
          label="Full Tests"
          border
        >
          <b>Full Tests</b></el-radio
        >
        <el-radio
          v-model="searchTest.type"
          label="Modules"
          border
        >
          <b>Modules</b></el-radio
        >
        <el-radio
          v-model="searchTest.type"
          label="Drills"
          border
        >
          <b>Drills</b></el-radio
        >
      </el-form-item> -->
      <el-form-item label="Keywords">
        <el-input
          ref="filterInput"
          placeholder="Name/Email/Test Name"
          v-model="searchTest.search"
          @keyup.enter.native="search"
        >
          <el-button
            el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          >
            Search
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <hr />
    <template v-if="results">
      <el-alert class="mt-4 mb-3" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <!-- <el-form
        :inline="true"
        :model="search"
        class="demo-form-inline"
        style="margin-top:20px"
        size="small"
      >
        <el-form-item label="Student">
          <el-input
            v-model="search.user_keyword"
            placeholder="Name/Username/Email"
          ></el-input>
        </el-form-item>
        <el-form-item label="Test Name">
          <el-input
            v-model="search.test_title"
            placeholder="Test Name"
          ></el-input>
        </el-form-item>
        <el-form-item class="search-button-item">
          <el-button type="success" @click="searchPage" class="search-button"
            >Search</el-button
          >
        </el-form-item>
      </el-form> -->
      <!-- <el-menu :default-active="active" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1" class="menu-item"
          ><b
            ><router-link
              :to="{
                query: {
                  type: 'composePractices'
                }
              }"
              class="linkInTab"
              >Full Tests</router-link
            ></b
          ></el-menu-item
        >
        <el-menu-item index="2" class="menu-item"
          ><b
            ><router-link
              :to="{
                query: {
                  type: 'singlePractice'
                }
              }"
              class="linkInTab"
            >
              Modules</router-link
            ></b
          ></el-menu-item
        >
        <el-menu-item index="3" class="menu-item"
          ><b
            ><router-link
              :to="{
                query: {
                  type: 'drill'
                }
              }"
              class="linkInTab"
              >Drills</router-link
            ></b
          ></el-menu-item
        >
      </el-menu> -->
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :page-count="6"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-radio-group
        v-model="searchTest.status"
        @change="search"
        size="medium"
      >
        <el-radio-button :label="null">All</el-radio-button>
        <el-radio-button :label="`finished`">{{
          $t("courses.completed")
        }}</el-radio-button>
        <el-radio-button :label="`started`">{{
          $t("courses.Incomplete")
        }}</el-radio-button>
      </el-radio-group>
      <TestResultsTable
        :type="searchTest.testType"
        :results="results"
        role="admin"
        @remove="getAllResults"
      ></TestResultsTable>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :page-count="6"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant, user } from "@ivy-way/material";
import TestResults from "@/views/testResults/apis/testResults";
import TestResultsTable from "@/views/testResults/components/TestResultsTable";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: "TestResults - " + this.CompanyName
    };
  },

  components: {
    TestResultsTable,
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      Tests: ["SAT", "ACT"],
      searchTest: {
        testType: "SAT",
        search: null,
        is_finished: null
      },
      results: null,
      resultsTotal: 0,
      pageSize: 0,
      role: "admin"
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return "Test Results";
    },
    page() {
      let page = 1;
      if (this.$route.query.page) {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    routeName() {
      return this.$route.name;
    }
  },
  watch: {},

  async mounted() {
    this.searchTest.testType = this.$route.query.testType
      ? this.$route.query.testType
      : "SAT";
    this.searchTest.search = this.$route.query.search
      ? this.$route.query.search
      : null;
    this.searchTest.status =
    this.$route.query.status
      ? this.$route.query.status
      : null;
    this.getAllResults();
  },

  methods: {
    async search() {
      this.$router.replace({
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          ...this.searchTest,
          page: 1
        }
      });
    },
    async getAllResults() {
      let results = null;
      results = await TestResults.getTestResults({
        page: this.page,
        test_name: this.searchTest.testType,
        keyword: this.searchTest.search,
        status: this.searchTest.status
      });
      this.results = results.user_exams.data;
      this.pageSize = results.user_exams.per_page;
      this.resultsTotal = results.user_exams.total;
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    searchPage() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title
        }
      });
    }
  }
};
</script>

<style scoped>
.box .container {
  padding-top: 0;
}
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
.linkInTab {
  color: inherit !important;
  display: block;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}
.menu-item {
  height: 40px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .box .container {
    padding: 0 15px 20px 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sat_left {
    margin: 0;
    margin-top: 10px;
  }
  .sat_left > p {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>
