var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.Results)?_c('div',[_c('el-table',{attrs:{"data":_vm.Results,"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"label":"Student","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'SuperAdminViewUser',
            params: { uid: scope.row.user.id }
          }}},[_c('b',[_vm._v(" "+_vm._s(_vm.user.displayName( scope.row.user.first_name, scope.row.user.last_name ))+" ")])])]}}],null,false,975754478)}),_c('el-table-column',{attrs:{"label":"Email","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_vm._v(_vm._s(scope.row.user.email))])]}}],null,false,1660071003)}),_c('el-table-column',{attrs:{"label":"Test","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.finished_at)?_c('router-link',{attrs:{"to":{
            name: _vm.type + 'ResultPage',
            params: { userExamId: scope.row.id }
          }}},[_c('b',[_vm._v(" "+_vm._s(scope.row.exam.name)+" ")])]):_c('span',[_vm._v(" "+_vm._s(scope.row.exam.name)+" ")])]}}],null,false,732969538)}),_c('el-table-column',{attrs:{"label":"Score","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.finished_at)?_c('b',{staticClass:"text-success text-bold"},[_vm._v(" "+_vm._s(Number(scope.row.score))+" ")]):_c('span',[_vm._v(" - ")])]}}],null,false,337664000)}),_c('el-table-column',{attrs:{"label":"Status","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":!scope.row.finished_at ? 'info' : 'success',"disable-transitions":""}},[(!scope.row.finished_at)?_c('span',[_vm._v(" Incomplete ")]):_c('b',[_vm._v("Completed")])])]}}],null,false,3747364511)}),_c('el-table-column',{attrs:{"label":"Date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.moment(scope.row.updated_at).format('YYYY-MM-DD HH:mm'),"placement":"top"}},[_c('span',[_vm._v(_vm._s(_vm.instant.formatGoogleTime(scope.row.updated_at)))])])]}}],null,false,4158815413)}),_c('el-table-column',{attrs:{"label":"Actions","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"View","placement":"top"}},[_c('router-link',{attrs:{"to":{
                name: _vm.type + 'ResultPage',
                params: { userExamId: scope.row.id }
              }}},[_c('el-button',{staticStyle:{"width":"50px"},attrs:{"type":"success","size":"small"}},[_c('i',{staticClass:"fa fa-eye"})])],1)],1)]]}}],null,false,245046482)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }